* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.mDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 30px;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 95%;
  border-spacing: 0 10px;
}

th {
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  background-color: #d1d4db;
  color: #fff;
  border: solid 0px #000;
}

td,
th {
  padding: 12px 8px;
}

td {
  font-weight: bold;
  text-transform: capitalize;
  border: solid 0px #000;
  border-style: none solid solid none;
}

td {
  padding: 10px;
}

td:first-child,
th:first-child {
  border-left-style: solid;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

td:last-child,
th:last-child {
  border-right-style: solid;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

tr:nth-child(odd) {
  background-color: #fff8bc;
}

tr {
  transition: all 0.3s;
}

tr:hover {
  background-color: #ffe898;
}

.namePTag {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.name {
  margin-left: 20px;
}

.userImg {
  height: 48px;
  width: 48px;
  border-radius: 50%;
}

.topDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.inpDiv {
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  width: 80%;
  display: flex;
  align-items: center;
  padding: 0px 0px 0px 10px;
  margin-right: 20px;
  border-radius: 8px;
  border-style: solid;
  border-width: 1px;
  border-color: lightgray;
}

.nameTH {
  cursor: pointer;
}

#inpSearch {
  outline: none;
  width: 100%;
  border-radius: 8px;
  border-width: 0px;
  padding: 12px 8px;
}

.SearchIcon {
  color: #1f388c;
}

.mDivCSV {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 10px;
  border-radius: 8px;
  color: #1f388c;
  border-style: solid;
  border-width: 2px;
  border-color: #1f388c;
  cursor: pointer;
  transition: all 0.3s;
}

.mDivCSV:hover {
  box-shadow: 5px 5px 10px lightgray;
}

.mDivCSV p {
  margin-top: -5px;
  margin-left: 5px;
  font-weight: bold;
}

.linkRemove {
  text-decoration: none;
}

.loadingDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}
